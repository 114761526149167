
/*
枚举类型
*/

// 订单管理类型
export enum KinmenType {
    'STATUS_YMS_NO', //送签失败
    'STATUS_AUDIT_NO', //审核拒绝
    'all_count', //全部
    'STATUS_WAITING_SUBMIT', //待支付
    'STATUS_PAY_SUS', //待提交资料
    'STATUS_SUBMIT_TRUE', //待审核
    'STATUS_AUDIT_YES', //审核通过
    'STATUS_YMS_WAITING', //待送签
    'STATUS_YMS_YES', //已送签
    'STATUS_COMPLETED' //已完成
}

// 接口Code状态
export enum APIResponseCodeType {
    'success' = 1,    // 成功
    'error' = 0,    // 失败
    'redirect' = -1,   // 重定向
    'page' = 2,    // 跳转页面
}

// 页面模式
export enum PageMode {
    'ADD' = 'add',    // 添加
    'EDIT' = 'edit',   // 编辑
}

// 订单管理类型
export enum OrderType {
    'all' = '', //全部
    'wait' = 0, //待支付
    'finish' = 1, //已完成
}